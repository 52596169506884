import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  interviewHost: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.interviewHost`,
    defaultMessage: 'Interview Host',
  },
  interviewHostDesc: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.interviewHostDesc`,
    defaultMessage:
      'Select who will serve as the host for your interview. Fountain does not currently support mixed host types.',
  },
});
